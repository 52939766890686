import React from "react";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../layout";
import PostListing from "../components/PostListing/PostListing";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./listing.css";

class Listing extends React.Component {
  renderPaging() {
    const { currentPageNum, pageCount } = this.props.pageContext;
    const prevPage = currentPageNum - 1 === 1 ? "blog" : `blog/${currentPageNum - 1}/`;
    const nextPage = `blog/${currentPageNum + 1}/`;
    const isFirstPage = currentPageNum === 1;
    const isLastPage = currentPageNum === pageCount;

    return (
      <div className="column is-three-fifths-desktop">
        <nav className="pagination is-rounded" role="navigation" aria-label="pagination">
          {!isFirstPage && <Link to={prevPage} className="pagination-previous">Previous</Link>}
          {[...Array(pageCount)].map((_val, index) => {
            const pageNum = index + 1;
            return (
              <ul key={`listing-page-${pageNum}`} className="pagination-list">
                <li>
                  <Link
                    className="pagination-link" 
                    activeClassName="is-active" 
                    key={`listing-page-${pageNum}`}
                    to={pageNum === 1 ? "/blog" : `blog/${pageNum}/`}
                  >
                    {pageNum}
                  </Link>
                </li>
              </ul>
            );
          })}
          {!isLastPage && <Link to={nextPage} className="pagination-next">Next</Link>}
        </nav>
      </div>
    );
  }

  render() {
    const postEdges = this.props.data.allMarkdownRemark.edges;

    return (
      <Layout>
        
        <Helmet title={`Blog | ${config.siteTitle}`} />
        <SEO />

        <div className="blog-container">

          <section className="hero is-medium">

            <div className="hero-body">

              <div className="container">

                <div className="columns is-centered">
                  <PostListing postEdges={postEdges} />
                </div>

                <div className="columns is-centered">
                  {this.renderPaging()}
                </div>

              </div>

            </div>

          </section>

        </div>
      </Layout>
    );
  }
}

export default Listing;

/* eslint no-undef: "off" */
export const listingQuery = graphql`
  query ListingQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
          }
        }
      }
    }
  }
`;
